import axios from "axios";

// const offline = true

const state = () => ({
  user: null,
});

const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },
  RESET_USER(state) {
    state.user = null;
  },
};

const actions = {
  async loginWithPotensa(context, token) {
    const { dispatch } = context;
    await dispatch("logout");
    const user = { token };
    axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;
    localStorage.setItem("ytc_lms_auth", JSON.stringify(user));
    localStorage.setItem("ytc_lms_token", user.token);
    localStorage.setItem("ytc_lms_counter_open_tab", 0);
    localStorage.setItem("ytc_lms_counter_face", 0);
    localStorage.setItem("ytc_lms_counter_speech", 0);
    localStorage.setItem("ytc_lms_use_face_detection", false);
    localStorage.setItem("ytc_lms_use_speech_detection", false);
    return await dispatch("get_user");
  },
  async login({ commit }, user) {
    return new Promise((resolve, reject) => {
      user.access_key = process.env.VUE_APP_ACCESS_KEY;
      user.provider = "email";
      axios
      .post("/users/v1/member/login", user, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => res.data)
      .then((res) => {
        let user = res.data;
        let token = `Bearer ${user.token}`;
        
        axios.defaults.headers.common["Authorization"] = token;
        localStorage.setItem("ytc_lms_auth", JSON.stringify(user));
        localStorage.setItem("ytc_lms_token", user.token);
        localStorage.setItem("ytc_lms_counter_open_tab", 0);
        localStorage.setItem("ytc_lms_counter_face", 0);
        localStorage.setItem("ytc_lms_counter_speech", 0);
        localStorage.setItem("ytc_lms_use_face_detection", false);
        localStorage.setItem("ytc_lms_use_speech_detection", false);
        commit("SET_USER", user);
        resolve("success");
      })
      .catch((error) => {
        reject(error);
      });
    });
  },
  
  async sso_login({ commit }, user) {
    return new Promise((resolve, reject) => {
      axios
      .post(
        "/users/v1/member/auto_login_register",
        {
          access_key: process.env.VUE_APP_ACCESS_KEY,
          email: user.email,
          fullname: user.fullname,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
        )
        .then((res) => res.data)
        .then((res) => {
          let user = res.data;
          let token = `Bearer ${user.token}`;
          
          axios.defaults.headers.common["Authorization"] = token;
          localStorage.setItem("ytc_lms_auth", JSON.stringify(user));
          localStorage.setItem("ytc_lms_token", user.token);
          localStorage.setItem("ytc_lms_counter_open_tab", 0);
          localStorage.setItem("ytc_lms_counter_face", 0);
          localStorage.setItem("ytc_lms_counter_speech", 0);
          localStorage.setItem("ytc_lms_use_face_detection", false);
          localStorage.setItem("ytc_lms_use_speech_detection", false);
          commit("SET_USER", user);
          resolve("success");
        })
        .catch((error) => {
          reject(error);
        });
      });
    },
    
    async login_google({ commit }, id_token) {
      console.log("is token", id_token);
      const data = {
        access_key: process.env.VUE_APP_ACCESS_KEY,
        email: "",
        password: "",
        provider: "google",
        access_token: id_token,
      };
      return new Promise((resolve, reject) => {
        axios
        .post("/users/v1/member/login", data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => res.data)
        .then((res) => {
          let user = res.data;
          let token = `Bearer ${user.token}`;
          
          axios.defaults.headers.common["Authorization"] = token;
          localStorage.setItem("ytc_lms_auth", JSON.stringify(user));
          localStorage.setItem("ytc_lms_token", user.token);
          localStorage.setItem("ytc_lms_counter_open_tab", 0);
          localStorage.setItem("ytc_lms_counter_face", 0);
          localStorage.setItem("ytc_lms_counter_speech", 0);
          localStorage.setItem("ytc_lms_use_face_detection", false);
          localStorage.setItem("ytc_lms_use_speech_detection", false);
          commit("SET_USER", user);
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
      });
    },
    
    async login_facebook({ commit }, id_token) {
      return new Promise((resolve, reject) => {
        axios
        .post(
          "/users/v1/member/login",
          {
            access_key: process.env.VUE_APP_ACCESS_KEY,
            email: "",
            password: "",
            provider: "facebook",
            access_token: id_token,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
          )
          .then((res) => res.data)
          .then((res) => {
            let user = res.data;
            let token = `Bearer ${user.token}`;
            
            axios.defaults.headers.common["Authorization"] = token;
            localStorage.setItem("ytc_lms_auth", JSON.stringify(user));
            localStorage.setItem("ytc_lms_token", user.token);
            localStorage.setItem("ytc_lms_counter_open_tab", 0);
            localStorage.setItem("ytc_lms_counter_face", 0);
            localStorage.setItem("ytc_lms_counter_speech", 0);
            localStorage.setItem("ytc_lms_use_face_detection", false);
            localStorage.setItem("ytc_lms_use_speech_detection", false);
            commit("SET_USER", user);
            resolve(res.data);
          })
          .catch((error) => {
            reject(error);
          });
        });
      },
      
      async logout({ commit }) {
        return new Promise((resolve) => {
          commit("RESET_USER");
          localStorage.removeItem("ytc_lms_auth");
          localStorage.removeItem("ytc_lms_token");
          localStorage.removeItem("ytc_lms_counter_open_tab");
          localStorage.removeItem("ytc_lms_counter_face");
          localStorage.removeItem("ytc_lms_counter_speech");
          localStorage.removeItem("ytc_lms_license");
          localStorage.removeItem("ytc_lms_institution");
          localStorage.removeItem("ytc_lms_use_face_detection");
          localStorage.removeItem("ytc_lms_use_speech_detection");
          delete axios.defaults.headers.common["Authorization"];
          resolve("logged out");
        });
      },
      
      async get_user({ commit }) {
        let user = JSON.parse(localStorage.getItem("ytc_lms_auth"));
        commit("SET_USER", user);
        
        if (!user) {
          localStorage.removeItem("ytc_lms_auth");
          localStorage.removeItem("ytc_lms_token");
          return;
        }
        
        let token = user.token;
        let bearer = `Bearer ${token}`;
        
        axios.defaults.headers.common["Authorization"] = bearer;
        
        return await axios
        .get("/users/v1/member/detail")
        .then((res) => res.data)
        .then((res) => {
          let user = res.data;
          user.token = token;
          
          localStorage.setItem("ytc_lms_auth", JSON.stringify(user));
          localStorage.setItem("ytc_lms_token", user.token);
          commit("SET_USER", user);
        })
        .catch((error) => {
          commit("RESET_USER");
          localStorage.removeItem("ytc_lms_auth");
          localStorage.removeItem("ytc_lms_token");
          delete axios.defaults.headers.common["Authorization"];
          // console.error(error);
          throw error
        });
      },
    };
    
    const getters = {
      user: (state) => state.user,
      isLoggedIn: (state) => !!state.user && !!state.user.token,
    };
    
    export default { state, mutations, actions, getters };
    
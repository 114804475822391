<template>
  <v-row align-content="center">
    <v-col class="py-0 mx-auto" md="8">
      <v-row>
        <v-col class="white px-12 mx-auto" md="10" style="height: 100vh">
          <v-spacer class="py-16"></v-spacer>
          <div class="px-8">
            <v-row class="mb-4" justify="center">
              <v-col sm="6" lg="4" class="text-center">
                <img src="@/assets/images/success.png">
              </v-col>
            </v-row>
            <div class="fs-32 mb-5 text-center font-weight-bold">
              Kode OTP berhasil dikirim
            </div>
            <div class="fs-18 text-center color-disable">
              <span>Cek email anda kemudian copy paste Kode yang telah kami kirimkan untuk melanjutkan proses, tunggu sejenak Anda akan diarahkan ke halaman konfirmasi kode otp</span>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "success-send-email",
  created() {
    setTimeout(() =>{
      this.$router.push(`/password-reset/${this.$route.query.code}`);
    }, 8000)
  }
};
</script>

<style>
</style>
<template>
  <v-col cols="12" md="12" class="white rounded-lg">
    <v-card elevation="0" class="px-3 py-3 col-md-8 ma-auto" width="682" height="650">
      <div class="fs-24 color-state font-weight-bold mb-2">
        Informasi Ketentuan Spesifikasi Perangkat
        dan Aktifitas Peserta Saat Test Berlangsung
      </div>
      <p class="color-disable mb-0">
        Sebelum melanjutkan ke verifikasi perangkat mohon perhatikan hal-hal berikut 
      </p>
      <v-card-text class="pa-0" style="height: 400px;">
        <v-card-title class="fs-18 font-weight-medium color-state pl-0 pb-2">
          
          <v-spacer></v-spacer>
        </v-card-title>
        <div>
          <v-list dense class="py-0 ">
            <template v-for="(item, i) in specs" >

              <v-list-item :key="i" class="py-0">
                <v-list-item-avatar>
                  <v-icon color="primary">{{item.icon}}</v-icon>
                </v-list-item-avatar>

                <v-list-item-content class="py-0">
                  <v-list-item-title class="content-guide color-disable">
                    <div class="text-wrap fs-16" v-html="item.title">
                      {{ item.title }}
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn 
          color="primary" 
          large
          outlined
          elevation="0"
          class="mb-2 px-8 fs-14"
          @click="$router.push('/epot')">
          Kembali
        </v-btn>  
        <v-btn 
          color="primary" 
          large
          elevation="0"
          class="mb-2 fs-14 px-5"
          @click="goTest()"
        >
          Lanjutkan Pengecekan
        </v-btn>
      </v-card-actions>
      <p class="font-weight-medium color-state" style="bottom:0px">
        Mengalami kendala? Hubungi admin AEPT ke WhatsApp <span style="color:#652065">0813-2614-9338</span>
      </p>
    </v-card>
  </v-col>
</template>


<script>
export default {
  data() {
    return {
      specs : [
        {
          icon : "mdi-check-circle-outline",
          title : `Diutamakan perangkat memiliki minimal <span class="font-weight-medium color-state"> RAM 4gb </span>.`,
        },
        {
          icon : "mdi-check-circle-outline",
          title : "Pastikan koneksi jaringan internet stabil saat test berlangsung"
        },
        {
          icon : "mdi-check-circle-outline",
          title : `Pastikan tidak membuka program maupun tab lain. selain halaman test agar tidak memenuhi <span class="font-weight-medium color-state"> RAM </span>.`
        }
      ]
    }
  },
  methods: {
    goTest() {
      // let id = this.$route.params.id;
      // this.$router.push('/epot/webcam-check', id);
      // this.$router.push({ name: "epot-webcam-check" , params: { id: this.$route.params.id } });
      this.$router.push({ name: "epot-device-check" , params: { id: this.$route.params.id } });
    },
  }
}
</script>
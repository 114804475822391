<template>
    <v-card elevation="0" class="transparent px-2 py-2">
        <div class="fs-24 color-state font-weight-bold mb-2">
            <span>Sertifikat Amikom English Proficiency Test</span> <br>
            <v-btn
            text
            @click="hasHistory() ? $router.go(-1) : $router.push('/')"
            color="primary">
                <v-icon left>
                    mdi-chevron-left
                </v-icon>
                Kembali
            </v-btn>
        </div>
        <v-card-text class="px-0">
            <v-card class="rounded-lg pt-6 px-6 pb-15" elevation="0">
                <v-row>
                    <v-col>
                        <h4>Detail Kurir</h4>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col md="2">
                        <span class="label">Waybill</span>
                    </v-col>
                    <v-col md="10">
                        <span>{{ tracking.waybill_id }}</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col md="2">
                        <span class="label">Jenis Pengiriman</span>
                    </v-col>
                    <v-col md="10">
                        <span>{{ tracking.courier.company }} - {{ tracking.courier.type }} </span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col md="2">
                        <span class="label">Nama Driver</span>
                    </v-col>
                    <v-col md="10">
                        <span>{{ tracking.courier.name }}</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col md="2">
                        <span class="label">No Telp Driver</span>
                    </v-col>
                    <v-col md="10">
                        <span>{{ tracking.courier.phone }}</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col md="2">
                        <span class="label">Status</span>
                    </v-col>
                    <v-col md="10">
                        <span>{{ tracking.status }}</span>
                    </v-col>
                </v-row>
                <v-divider class="my-6"></v-divider>
                <v-row>
                    <v-col>
                        <h4>Detail Tracking</h4>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col md="2">
                        <span class="label">Dikirim dari</span>
                    </v-col>
                    <v-col md="10">
                        <span>{{ tracking.origin.contact_name }} - {{ tracking.origin.address }}</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col md="2" class="mb-3">
                        <span class="label">Tujuan</span>
                    </v-col>
                    <v-col md="10">
                        <span>{{ tracking.destination.contact_name }} - {{ tracking.destination.address }}</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col md="2">
                        <span class="label">Riwayat</span>
                    </v-col>
                    <v-col md="10">
                        <div class="item"
                        v-for="(histry,key) in tracking.history"
                        :key="key" >
                            <div class="time inline">
                                <span>{{ getTime(histry.updated_at) }}</span><br>
                                <span>{{ getDate(histry.updated_at) }}</span>
                            </div>
                            <div class="timeline inline">
                                <span class="point"></span>
                            </div>
                            <div class="notes inline">
                                {{ histry.note }}
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </v-card-text>
    </v-card>
</template>

<script>
import moment from 'moment';
export default {
    name: "certif-tracking",
    data() {
        return {
            tracking: {
                shipper: {
                    name: "",
                    email: "",
                    phone: "",
                    organization: ""
                },
                origin: {
                    contact_name: "",
                    contact_phone: "",
                    address: "",
                    note: "",
                    postal_code: 0
                },
                destination: {
                    contact_name: "",
                    contact_phone: "",
                    contact_email: "",
                    address: "",
                    note: "",
                    postal_code: 0
                },
                courier: {
                    tracking_id: "",
                    waybill_id: "WYB-",
                    company: "jne",
                    name: "",
                    phone: "",
                    type: "reg",
                    link: "",
                    history: [
                    ]
                },
                delivery: {
                    datetime: "2021-09-24T22:58+07:00",
                    note: "",
                    type: "now"
                },
                items: [
                    {
                        description: "Goods",
                        height: 10,
                        length: 10,
                        name: "SERTIFIKAT-059KBZDVZD8KCV",
                        quantity: 1,
                        value: 165000,
                        weight: 200,
                        width: 10
                    }
                ],
                price: 9000,
                note: "note order",
                status: "picking_up"
            }
        }
    },
    created() {
        this.getData();
    },
    methods: {
        getData(){
            this.axios
            .get(`/users/v1/member/order/shipping/tracking/`+this.$route.params.id)
            .then((response) => {
            let res = response.data;
            this.loading = false;
            if (response.status == 200) {
                this.loading = false;
                this.tracking = res.data;
                console.log(res);
            }
            });
        },
        getTime(a) {
            return moment(a).format("HH:MM");
        },
        getDate(a) {
            return moment(a).format("DD MMMM YYYY");
        },
        hasHistory() {
            return window.history.length > 2;
        },
    }
}

</script>

<style>
    span.label {
        font-weight: 600;
        color: #888888;
    }
    .inline {
        display: inline-block;
        vertical-align: top;
    }
    .item .time {
        width: 130px;
    }
    .item .timeline {
        width: 100px;
        height: 100px;
        position: relative;
    }
    .item:not(:last-child) .timeline::after {
        content: '';
        position: absolute;
        width: 3px;
        /* background-color: #eee; */
        background-color: #652065;
        top: 0;
        bottom: 0;
        left: 50%;
        margin-left: -3px;
        transform: translateY(10px);
    }
    span.point {
        position: absolute;
        width: 19px;
        height: 19px;
        /* background-color: #fff;
        border: 3px solid #eee; */
        background-color: #652065;
        border: 3px solid #652065;
        border-radius: 100%;
        left: -4px;
        right: 0;
        top: 3px;
        margin: auto;
        z-index: 1;
    }
</style>
<template>
  <v-row align-content="center" no-gutters>
    <v-col class="py-0" md="6">
      <v-row>
        <v-col class="white px-12" md="12" style="height: 100vh">
          <!-- <v-spacer class="py-10"></v-spacer> -->
          <v-row class="d-flex justify-center align-center my-10">
            <v-col cols="5" class="text-right">
              <img
                width="250"
                :src="require('@/assets/logo/logo_amikom.png')"
              />
            </v-col>
            <div
              style="border-left: 2px solid #000; height: 80px"
              class=""
            ></div>
            <v-col cols="5" class="text-left">
              <div class="text-h3 font-weight-medium ml-5">AEPT</div>
            </v-col>
          </v-row>

          <div class="text-h4 font-weight-medium mb-4 text-center">LOGIN</div>

          <v-col v-if="!showForm" cols="12" class="">
            <v-btn
              large
              elevation="0"
              type="submit"
              color="primary"
              block
              @click="login"
            >
              LOGIN WITH POTENSA
            </v-btn>
          </v-col>

          <v-form
            v-if="showForm"
            class="px-8"
            @submit.prevent="submit"
            lazy-validation
            ref="form"
            v-model="valid"
          >
            <!-- <div class="text-h4 font-weight-medium mb-4 text-center">LOGIN</div> -->
            <!-- <div class="color-disable mb-4">
              Belum mempunyai akun ?
              <router-link
                class="font-weight-medium text-decoration-none"
                tabindex="-1"
                color="primary"
                :to="{ name: 'registration' }"
              >
                <span>Daftar sekarang</span>
              </router-link>
            </div> -->

            <v-alert
              type="error"
              text
              dense
              prominent
              v-show="response !== null"
              >{{ response }}</v-alert
            >
            <v-alert
              type="success"
              text
              dense
              prominent
              v-show="responsesukses !== null"
              >{{ responsesukses }}</v-alert
            >
            <v-row>
              <v-col class="pb-1">
                <div class="mb-2">
                  <span class="font-weight-medium">Email</span>
                </div>
                <v-text-field
                  class=""
                  v-model="email"
                  placeholder="Enter your email"
                  :rules="emailRules"
                  autofocus
                  required
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="mb-2">
                  <span class="font-weight-medium">Password</span>
                  <router-link
                    :to="{ name: 'password-send-email' }"
                    class="float-right text-decoration-none"
                    tabindex="-1"
                  >
                    Lupa Password?
                  </router-link>
                </div>
                <v-text-field
                  class=""
                  v-model="password"
                  placeholder="Min. 8 characters"
                  :rules="pswRules"
                  outlined
                  hide-details="auto"
                  required
                  :type="show ? 'text' : 'password'"
                  :append-icon="show ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                  @click:append="show = !show"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="">
                <v-btn
                  large
                  elevation="0"
                  type="submit"
                  color="primary"
                  :loading="submiting"
                  block
                >
                  Masuk
                </v-btn>
              </v-col>
              <v-col cols="12" align="center" class="py-0">
                <!-- <div id="line">
                  <span class="color-disable font-weight-regular"
                    >Atau masuk dengan</span
                  >
                </div> -->
              </v-col>

              <v-col cols="12" class="">
                <v-btn
                  large
                  outlined
                  elevation="0"
                  color="primary"
                  block
                  @click="login"
                >
                  <span style="text-transform: none">
                    Login dengan POTENSA
                  </span>
                </v-btn>
              </v-col>

              <v-col cols="12" class="py-0">
                <v-row>
                  <v-col cols="" md="12">
                    <!-- v-google-signin-button="
                        '570852433891-ufqsaouf1i04tmp9tj8rjq6orj1pd852.apps.googleusercontent.com'
                      " -->
                    <!-- <v-btn
                      @click="loginWithGoogle"
                      large
                      elevation="0"
                      outlined
                      block
                      class="font-weight-medium"
                    >
                      <img src="@/assets/images/google.png" class="mr-1" />
                      Google
                    </v-btn> -->
                  </v-col>
                  <!-- <v-col cols="" md="6">
                    <v-btn
                      large
                      elevation="0"
                      type="submit"
                      outlined
                      block
                      class="font-weight-medium"
                      @click="loginWithFacebook"
                    >
                      <img src="@/assets/images/facebook.png" class="mr-1" />
                      Facebook
                    </v-btn>
                  </v-col> -->
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      md="6"
      class="d-flex justify-center flex-column px-0"
      style="
        background-color: rgba(0, 0, 0, 0);
        z-index: 9999;
        height: 100vh;
        width: 100wh;
      "
    >
      <div class="panduan-login" style="height: 100vh"></div>
      <!-- <div v-for="(guide, i) in guides" :key="i">
        <v-card :href="guide.link" flat target="_blank" class="mb-10 border-radius" style="cursor: pointer;background: transparent">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-h5 white--text font-weight-bold">{{guide.title}}</v-list-item-title>
              <v-list-item-subtitle class="subtitle-2 white--text mt-2">{{guide.subtitle}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <div style="border-top: 2px solid #fff;margin-left: 15px;" class="mt-5"></div>
        </v-card>
      </div> -->
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import firebase from "firebase/app";
import "firebase/auth";

export default {
  name: "login",
  data() {
    return {
      show: false,
      errors: [],
      valid: true,
      email: "",
      password: "",
      guides: [
        {
          title: "Panduan Pendaftaran",
          subtitle:
            "Silahkan lakukan pendaftaran apabila belum memiliki akun pada halaman ini.",
          link: "https://home.amikom.ac.id/",
        },
        {
          title: "Panduan Pembayaran",
          subtitle:
            "Silahkan lakukan pembayaran apabila belum melakukan pelunasan pemabayaran tes AEPT.",
          link: "https://home.amikom.ac.id/",
        },
        {
          title: "Panduan Akses Tes",
          subtitle:
            "Silahkan cermati panduan Akses Tes agar tidak ada kesalahan ketika sedang menjalankan tes.",
          link: "https://home.amikom.ac.id/",
        },
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      pswRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 8) || "Password must be at least 8 characters",
      ],
      submiting: false,
      response: null,
      responsesukses: null,
      isInit: false,
      isSignIn: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
    showForm() {
      return true;
    },
  },
  mounted() {},
  created() {
    // // let that = this;
    // let checkGauthLoad = setInterval(function() {
    //   this.isInit = this.$gAuth.isInit;
    //   this.isSignIn = this.$gAuth.isAuthorized;
    //   if (this.isInit) clearInterval(checkGauthLoad);
    // }, 1000);
  },
  methods: {
    // OnGoogleAuthSuccess(idToken) {
    //   // console.log(idToken);
    //   // Receive the idToken and make your magic with the backend
    // },
    login() {
      const a = document.createElement("a");
      a.href = "https://potensa.amikom.ac.id/";
      a.click();
    },
    OnGoogleAuthFail(error) {
      console.log(error);
    },
    async loginWithFacebook() {
      var provider = new firebase.auth.FacebookAuthProvider();

      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          /** @type {firebase.auth.OAuthCredential} */
          var credential = result.credential;
          console.log("Credential : ", credential);

          const additionalUserInfo = result.additionalUserInfo;
          console.log("additionalUserInfo : ", additionalUserInfo);

          // This gives you a Google Access Token. You can use it to access the Google API.
          var token = credential.accessToken;
          var IDtoken = credential.idToken;
          console.log("Token : ", token);
          console.log("IDtoken : ", IDtoken);
          // The signed-in user info.
          var user = result.user;
          console.log("user : ", user);

          // this.LoginSSO(additionalUserInfo, IDtoken)

          // setTimeout(() => {
          //   firebase.auth().signOut().then(() => {
          //     // Sign-out successful.
          //   }).catch((error) => {
          //     // An error happened.
          //   });
          // }, 10000)
          // ...
        })
        .catch((error) => {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log("errorCode : ", errorCode);
          console.log("errorMessage : ", errorMessage);
          // The email of the user's account used.
          var email = error.email;
          console.log("email : ", email);
          // The firebase.auth.AuthCredential type that was used.
          var credential = error.credential;
          console.log("credential : ", credential);
          // ...
        });

      // window.FB.login((response) => {
      //   if (response.status == "connected") {
      //     this.$store
      //       .dispatch("login_facebook", response.authResponse.accessToken)
      //       .then(() => {
      //         this.submiting = false;
      //         if (localStorage.getItem("ytc_lms_redirect") != null) {
      //           this.$router.push(localStorage.getItem("ytc_lms_redirect"));
      //           localStorage.removeItem("ytc_lms_redirect");
      //         } else {
      //           if(this.user.is_updated_profile){
      //             window.location.reload(true);
      //             this.$router.push("/epot");
      //           } else {
      //             // window.location.reload(true);
      //             this.$router.push("/account");

      //           }
      //           // this.$router.push("/dashboard");
      //         }
      //       })
      //       .catch((error) => {
      //         this.submiting = false;
      //         // this.$refs.form.reset();
      //         console.error(error);
      //         this.response = error;
      //       });
      //   }
      // });
    },
    async loginWithGoogle() {
      var provider = new firebase.auth.GoogleAuthProvider();

      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          /** @type {firebase.auth.OAuthCredential} */
          var credential = result.credential;
          // console.log('Credential : ', credential);

          const additionalUserInfo = result.additionalUserInfo;
          // console.log('additionalUserInfo : ', additionalUserInfo);

          // // This gives you a Google Access Token. You can use it to access the Google API.
          // var token = credential.accessToken;
          var IDtoken = credential.idToken;
          // console.log('Token : ', token);
          // // console.log('IDtoken : ', IDtoken);
          // // The signed-in user info.
          // var user = result.user;
          // console.log('user : ', user);

          this.LoginSSO(additionalUserInfo, IDtoken);
          // ...
        })
        .catch((error) => {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log("errorCode : ", errorCode);
          console.log("errorMessage : ", errorMessage);
          // The email of the user's account used.
          var email = error.email;
          console.log("email : ", email);
          // The firebase.auth.AuthCredential type that was used.
          var credential = error.credential;
          console.log("credential : ", credential);
          // ...
        });

      // const googleUser = await this.$gAuth.signIn();
      // // console.log('user',googleUser)
      // this.$store
      //   .dispatch("login_google", googleUser.wc.id_token)
      //   .then(() => {
      //     this.submiting = false;
      //     if (localStorage.getItem("ytc_lms_redirect") != null) {
      //       this.$router.push(localStorage.getItem("ytc_lms_redirect"));
      //       localStorage.removeItem("ytc_lms_redirect");
      //     } else {
      //       if(this.user.is_updated_profile){
      //             window.location.reload(true);
      //             this.$router.push("/epot");
      //           } else {
      //             // window.location.reload(true);
      //             this.$router.push("/account");

      //           }
      //       // this.$router.push("/dashboard");
      //     }
      //   })
      //   .catch((error) => {
      //     this.submiting = false;
      //     console.error('why',error);
      //     this.response = error;
      // });
    },

    async LoginSSO(user, IDtoken) {
      console.log(user);
      console.log(IDtoken);
      // console.clear()
      this.$store
        .dispatch("login_google", IDtoken)
        .then(() => {
          this.submiting = false;
          if (localStorage.getItem("ytc_lms_redirect") != null) {
            this.$router.push(localStorage.getItem("ytc_lms_redirect"));
            localStorage.removeItem("ytc_lms_redirect");
          } else {
            if (this.user.is_updated_profile) {
              window.location.reload(true);
              this.$router.push("/epot");
            } else {
              // window.location.reload(true);
              this.$router.push("/account");
            }
            // this.$router.push("/dashboard");
          }
        })
        .catch((error) => {
          this.submiting = false;
          // console.log('why',error.response);
          if (error.response) {
            let errorData = error.response.data;
            let errorCode = error.response.status;
            // let errorHeader = error.response.headers;

            if (errorCode === 401) {
              this.response = errorData.message;
            } else {
              this.response = errorData.message;
            }
          } else {
            this.$store.state.errorNetwork = true;
            this.process.run = false;
            this.process.form = false;
          }
          // this.response = error;
        });
    },
    submit() {
      this.submiting = true;
      if (this.$refs.form.validate()) {
        let email = this.email;
        let password = this.password;

        this.$store
          .dispatch("login", { email, password })
          .then(() => {
            this.submiting = false;
            if (localStorage.getItem("ytc_lms_redirect") != null) {
              this.$router.push(localStorage.getItem("ytc_lms_redirect"));
              localStorage.removeItem("ytc_lms_redirect");
            } else {
              if (this.user.is_updated_profile) {
                window.location.reload(true);
                this.$router.push("/epot");
              } else {
                // window.location.reload(true);
                this.$router.push("/account");
              }
            }
          })
          .catch((error) => {
            this.submiting = false;
            this.$refs.form.reset();
            // console.error(error);
            // this.response = "Email dan password tidak cocok";
            if (error.response) {
              let errorData = error.response.data;
              let errorCode = error.response.status;
              // let errorHeader = error.response.headers;

              if (errorCode === 401) {
                this.response = errorData.message;
              } else {
                this.response = errorData.message;
              }
            } else {
              this.$store.state.errorNetwork = true;
              this.process.run = false;
              this.process.form = false;
            }
          });
      }
      // this.$store
      //   .dispatch("login", { username, password })
      //   .then(() => {
      //     this.submiting = false;
      //     if (sessionStorage.getItem("redirect") != null) {
      //       this.$router.push(sessionStorage.getItem("redirect"));
      //       sessionStorage.removeItem("redirect");
      //       return;
      //     }
      //     this.$router.push("/dashboard");
      //   })
      //   .catch((errors) => {
      //     this.submiting = false;
      //     console.log(errors);
      //     this.response = "Akun ini tidak terdaftar";
      //     if (errors.response != undefined) {
      //       this.submiting = false;
      //       this.errors = errors.response.data.errors;
      //       this.response = "Akun ini tidak terdaftar";
      //     }
      //   });
      // }, 1000);
      // }
      else if (this.password.length < 8) {
        this.response = "password kurang dari 8 character";
        this.submiting = false;
      } else {
        this.submiting = false;
        this.response = "Email dan password tidak boleh kosong";
        this.$refs.form.reset();
      }
    },
  },
};
</script>

<style>
.box-shadow {
  box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.2) !important;
}
.border-radius {
  border-radius: 10px !important;
}
.card-guide:hover {
  border: 2px solid #652065;
}
.panduan-login {
  background: linear-gradient(rgba(106, 1, 106, 0.8), rgba(125, 47, 47, 0.8)),
    url("../../assets/images/bg_login.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
}
.google-signin-button {
  color: white;
  background-color: red;
  height: 50px;
  font-size: 16px;
  border-radius: 10px;
  padding: 10px 20px 25px 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
#line {
  width: 100%;
  height: max-content;
  text-align: center;
  position: relative;
}

#line:after {
  content: "";
  width: 100%;
  border-bottom: solid 1px #e0e6ed;
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 1;
}

#line span {
  width: auto;
  background: #fff;
  display: inline-block;
  z-index: 3;
  padding: 0 20px 0 20px;
  position: relative;
  margin: 0;
}
</style>
